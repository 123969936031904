<template>
    <div>
        <MainUserComponent :parent="parent">
        </MainUserComponent>
    </div>
</template>

<script>
import MainUserComponent from '../mainsUsersComponent'
import appConfig from '@/app.config'

export default {
    page: {
        title: 'Bronze Users',
        meta: [{ name: 'description', content: appConfig.description }],
    },
    name: 'tagged-users',
    components: {
        MainUserComponent,
    },
    data() {
        return {
            parent: {
                name: 'UserSegments',
                dispatchName: 'admin/users/getUserSegments',
                searchDispatchName: 'admin/users/searchUserSegments',
                category: {
                    category: 3,
                    page: 1
                }
            },
        }
    },
}
</script>

<style lang="scss" scoped>
#enter__account {
    font-family: 'Graphik Regular';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 13px;
    color: #F7921C;
    outline: none;
    border: none;
    background-color: transparent;
}

#__edit {
    outline: none;
    border: none;
    background: transparent;
    margin-left: .5em;
    width: 10px !important;
    padding: 0px;
}
</style>